import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getcasinoDataAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import PlaceBet from "./components/PlaceBet";
import PlacedBet from "./components/PlacedBet";
import CasinoVideo from "./components/CasinoVideo";
import Timer from "./components/Timer";
import CasinoPage from "./components/CasinoPage";
import Chart from "react-google-charts";
export default function Baccarat() {
  const GAME_ID = "BACCARAT";
  const VIDEO_ID = CASINO_VIDEO.baccarat1;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const data = [
    ["Player", "percent"],
    ["Player", 9],
    ["Banker", 8],
    ["Tie", 2],
  ];

  const [selectedTab, setSelectedTab] = useState("game");
  const [casinoPlData, setCasinoPlData] = useState([]);

  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  const isMobile = window.innerWidth < 768;
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={VIDEO_ID}
      GAME_ID={GAME_ID}
      shortName={"baccarat"}
      gameName={"BACCARAT"}
    >
      <div className="casino-table ng-tns-c3502172659-7">
        <div className="casino-table-full-box ng-tns-c3502172659-7">
          <app-pie-chart
            style={{
              width: isMobile ? "100%" : "31%",
            }}
            className="ng-tns-c3502172659-7"
            _nghost-ng-c594452701
          >
            <div className="desktop-graph d-lg-inline d-none">
              <div
                style={{
                  width: "100%",
                }}
                className="baccarat-graph text-center"
              >
                <h4>
                  <b> Statistics </b>
                </h4>
                <div>
                  <div style={{ width: "100%" }}>
                    <Chart
                      chartType="PieChart"
                      data={data}
                      options={{
                        title: "Baccarat",
                      }}
                      width={"100%"}
                      height={"180px"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-graph d-lg-none d-block w-100">
              <div className="baccarat-graph text-center w-100">
                <div className="baccrat-graph-header">
                  <span>Statistics</span>
                </div>
              </div>
            </div>
          </app-pie-chart>
          <Chart
            chartType="PieChart"
            data={data}
            options={{
              title: "Baccarat",
            }}
            width={"100%"}
            style={{
              display: isMobile ? "block" : "none",
            }}
            height={"100px"}
          />
          <div className="baccarat-odds-container ng-tns-c3502172659-7">
            <div className="baccarat-other-odds ng-tns-c3502172659-7">
              <div className="baccarat-other-odd-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  className={`ng-tns-c3502172659-7 baccarat-other-odd-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() =>
                    setBet("Perferct Pair", casinoData, dispatch)
                  }
                >
                  <span className="ng-tns-c3502172659-7">
                    <span className="ng-tns-c3502172659-7">
                      Perferct Pair{" "}
                    </span>
                    <span className="ng-tns-c3502172659-7">
                      {getOddForEntity("Perferct Pair", casinoData)}
                      <span className="ng-tns-c3502172659-7 ng-star-inserted">
                        :1
                      </span>
                    </span>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Perferct Pair"
                  )}{" "}
                </div>
              </div>
              <div className="baccarat-other-odd-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  className={`ng-tns-c3502172659-7 baccarat-other-odd-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Big", casinoData, dispatch)}
                >
                  <span className="ng-tns-c3502172659-7">
                    <span className="ng-tns-c3502172659-7">Big </span>
                    <span className="ng-tns-c3502172659-7">
                      {getOddForEntity("Big", casinoData)}
                      <span className="ng-tns-c3502172659-7 ng-star-inserted">
                        :1
                      </span>
                    </span>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Big")}{" "}
                </div>
              </div>
              <div className="baccarat-other-odd-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  className={`ng-tns-c3502172659-7 baccarat-other-odd-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Small", casinoData, dispatch)}
                >
                  <span className="ng-tns-c3502172659-7">
                    <span className="ng-tns-c3502172659-7">Small </span>
                    <span className="ng-tns-c3502172659-7">
                      {getOddForEntity("Small", casinoData)}
                      <span className="ng-tns-c3502172659-7 ng-star-inserted">
                        :1
                      </span>
                    </span>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Small")}{" "}
                </div>
              </div>
              <div className="baccarat-other-odd-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  onClick={() => setBet("Either Pair", casinoData, dispatch)}
                  className={`ng-tns-c3502172659-7 baccarat-other-odd-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <span className="ng-tns-c3502172659-7">
                    <span className="ng-tns-c3502172659-7">Either Pair </span>
                    <span className="ng-tns-c3502172659-7">
                      {getOddForEntity("Either Pair", casinoData)}
                      <span className="ng-tns-c3502172659-7 ng-star-inserted">
                        :1
                      </span>
                    </span>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Either Pair"
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="baccarat-main-odds mt-3 ng-tns-c3502172659-7">
              <div className="player-pair-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  onClick={() => setBet("Player Pair", casinoData, dispatch)}
                  className={`ng-tns-c3502172659-7 player-pair-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <span className="ng-tns-c3502172659-7">
                    <div className="ng-tns-c3502172659-7">Player Pair</div>
                    <div className="ng-tns-c3502172659-7">
                      <span className="ng-tns-c3502172659-7">
                        {getOddForEntity("Player Pair", casinoData)}
                        <span className="ng-tns-c3502172659-7 ng-star-inserted">
                          :1
                        </span>
                      </span>
                    </div>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Player Pair"
                  )}{" "}
                </div>
              </div>

              <div className="player-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  className={`ng-tns-c3502172659-7 player-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Player", casinoData, dispatch)}
                >
                  <span className="ng-tns-c3502172659-7">
                    <div className="ng-tns-c3502172659-7">
                      <b className="ng-tns-c3502172659-7"> Player </b>
                    </div>
                    <div className="ng-tns-c3502172659-7">
                      <span className="ng-tns-c3502172659-7">
                        {getOddForEntity("Player", casinoData)}
                        <span className="ng-tns-c3502172659-7 ng-star-inserted">
                          :1
                        </span>
                      </span>
                    </div>
                    <div className="ng-tns-c3502172659-7 ng-star-inserted">
                      {casinoData?.data?.response?.cards &&
                        casinoData?.data?.response?.cards[0]?.map((item) => (
                          <img
                            className="ng-tns-c3502172659-7"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                          />
                        ))}
                    </div>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Player")}{" "}
                </div>
              </div>

              <div className="tie-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  className={`ng-tns-c3502172659-7 tie-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Tie", casinoData, dispatch)}
                >
                  <span className="ng-tns-c3502172659-7">
                    <div className="ng-tns-c3502172659-7">Tie</div>
                    <div className="ng-tns-c3502172659-7">
                      <span className="ng-tns-c3502172659-7">
                        {getOddForEntity("Tie", casinoData)}
                        <span className="ng-tns-c3502172659-7 ng-star-inserted">
                          :1
                        </span>
                      </span>
                    </div>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Tie")}{" "}
                </div>
              </div>

              <div className="banker-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  onClick={() => setBet("Banker", casinoData, dispatch)}
                  className={`ng-tns-c3502172659-7 banker-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <span className="ng-tns-c3502172659-7">
                    <div className="ng-tns-c3502172659-7">
                      <b className="ng-tns-c3502172659-7"> Banker </b>
                    </div>
                    <div className="ng-tns-c3502172659-7">
                      <span className="ng-tns-c3502172659-7">
                        {getOddForEntity("Banker", casinoData)}
                        <span className="ng-tns-c3502172659-7 ng-star-inserted">
                          :1
                        </span>
                      </span>
                    </div>
                    <div className="ng-tns-c3502172659-7 ng-star-inserted">
                      {casinoData?.data?.response?.cards &&
                        casinoData?.data?.response?.cards[1]?.map((item) => (
                          <img
                            className="ng-tns-c3502172659-7"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                          />
                        ))}
                    </div>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Banker")}{" "}
                </div>
              </div>

              <div className="banker-pair-box-container ng-tns-c3502172659-7 ng-star-inserted">
                <div
                  onClick={() => setBet("Banker Pair", casinoData, dispatch)}
                  className={`ng-tns-c3502172659-7 banker-pair-box ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <span className="ng-tns-c3502172659-7">
                    <div className="ng-tns-c3502172659-7">Banker Pair</div>
                    <div className="ng-tns-c3502172659-7">
                      <span className="ng-tns-c3502172659-7">
                        {getOddForEntity("Banker Pair", casinoData)}
                        <span className="ng-tns-c3502172659-7 ng-star-inserted">
                          :1
                        </span>
                      </span>
                    </div>
                  </span>
                </div>
                <div className="casino-nation-book text-center ng-tns-c3502172659-7 text-success">
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "Banker Pair"
                  )}{" "}
                </div>
              </div>
            </div>
            <div className="min-max ng-tns-c3502172659-7">
              <p className="ng-tns-c3502172659-7">Min: 100 Max: 300000</p>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
