//entrypoint
import axios from "axios";
import { getToken } from "../utils/constants";
import store from "../redux/store";
import bycrpt from "bcryptjs";
const { dispatch } = store;
export async function generateHash() {
  const timestamp = Date.now().toString();
  const random = Math.random().toString();
  const hash = await bycrpt.hash(timestamp + random, 10);
  return hash;
}
export const AXIOS_INSTANCE_WITHOUT_INTERCEPTOR = axios.create();

AXIOS_INSTANCE_WITHOUT_INTERCEPTOR.interceptors.request.use(async (config) => {
  const token = getToken();
  config.headers["x-origin"] = "e5dadeccee37872f08f365bb82005462";
  config.headers["x-browser-fingerprint"] = localStorage.getItem("fingerprint");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
AXIOS_INSTANCE_WITHOUT_INTERCEPTOR.interceptors.response.use(
  (response) => {
    const token = getToken();
    if (response.headers.authorization && token) {
      dispatch({
        type: "accountData/setToken",
        payload: response?.headers?.authorization.replace("Bearer", ""),
      });
    }
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      dispatch({ type: "accountData/logOut" });
      localStorage.clear();
      window.location.href = "/";
    }
    return error;
  }
);

export const AXIOS_INSTANCE = AXIOS_INSTANCE_WITHOUT_INTERCEPTOR;
