import React from "react";

export default function BTableRules() {
  return (
    <div className="modal-body">
      <div>
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html:
              '\n    .rules-section .row.row5 {\n        margin-left: -5px;\n        margin-right: -5px;\n    }\n\n    .rules-section .pl-2 {\n        padding-left: .5rem !important;\n    }\n\n    .rules-section .pr-2 {\n        padding-right: .5rem !important;\n    }\n\n    .rules-section .row.row5>[class*="col-"],\n    .rules-section .row.row5>[class*="col"] {\n        padding-left: 5px;\n        padding-right: 5px;\n    }\n\n    .rules-section {\n        text-align: left;\n        margin-bottom: 10px;\n    }\n\n    .rules-section .table {\n        color: #fff;\n        border: 1px solid #444;\n        background-color: #222;\n        font-size: 12px;\n    }\n\n    .rules-section .table td,\n    .rules-section .table th {\n        border-bottom: 1px solid #444;\n    }\n\n    .rules-section ul li,\n    .rules-section p {\n        margin-bottom: 5px;\n    }\n\n    .rules-section::-webkit-scrollbar {\n        width: 8px;\n    }\n\n    .rules-section::-webkit-scrollbar-track {\n        background: #666666;\n    }\n\n    .rules-section::-webkit-scrollbar-thumb {\n        background-color: #333333;\n    }\n\n    .rules-section .rules-highlight {\n        color: #FDCF13;\n        font-size: 16px;\n    }\n\n    .rules-section .rules-sub-highlight {\n        color: #FDCF13;\n        font-size: 14px;\n    }\n\n    .rules-section .list-style,\n    .rules-section .list-style li {\n        list-style: disc;\n    }\n\n    .rules-section .rule-card {\n        height: 20px;\n        margin-left: 5px;\n    }\n\n    .rules-section .card-character {\n        font-family: Card Characters;\n    }\n\n    .rules-section .red-card {\n        color: red;\n    }\n\n    .rules-section .black-card {\n        color: black;\n    }\n\n    .rules-section .cards-box {\n        background: #fff;\n        padding: 6px;\n        display: inline-block;\n        color: #000;\n        min-width: 150px;\n    }\n    ',
          }}
        />
        <div className="rules-section">
          <ul className="pl-4 pr-4 list-style">
            <li>
              The bollywood table game will be played with a total of 16 cards
              including (J,Q, K, A) these cards and 2 deck that means game is
              playing with total 16*2 = 32 cards
            </li>
            <li>
              <div className="cards-box">
                <span>If the card is</span>
                <span className="card-character black-card ml-1">A{"}"}</span>
                <span>Don Wins</span>
              </div>
            </li>
            <li>
              <div className="cards-box">
                <span>If the card is</span>
                <span className="card-character red-card ml-1">A{"{"}</span>
                <span className="card-character red-card ml-1">A[</span>
                <span className="card-character black-card ml-1">A]</span>
                <span>Amar Akbar Anthony Wins</span>
              </div>
            </li>
            <li>
              <div className="cards-box">
                <span>If the card is</span>
                <span className="card-character black-card ml-1">K{"}"}</span>
                <span className="card-character black-card ml-1">Q{"}"}</span>
                <span className="card-character black-card ml-1">J{"}"}</span>
                <span>Sahib Bibi aur Ghulam Wins.</span>
              </div>
            </li>
            <li>
              <div className="cards-box">
                <span>If the card is</span>
                <span className="card-character red-card ml-1">K[</span>
                <span className="card-character black-card ml-1">K]</span>
                <span>Dharam Veer Wins.</span>
              </div>
            </li>
            <li>
              <div className="cards-box">
                <span>If the card is</span>
                <span className="card-character red-card ml-1">K{"{"}</span>
                <span className="card-character black-card ml-1">Q]</span>
                <span className="card-character red-card ml-1">Q[</span>
                <span className="card-character red-card ml-1">Q{"{"}</span>
                <span>Kis Kisko Pyaar Karoon Wins.</span>
              </div>
            </li>
            <li>
              <div className="cards-box">
                <span>If the card is</span>
                <span className="card-character red-card ml-1">J{"{"}</span>
                <span className="card-character black-card ml-1">J]</span>
                <span className="card-character red-card ml-1">J[</span>
                <span>Ghulam Wins.</span>
              </div>
            </li>
          </ul>
          <ul className="pl-4 pr-4 list-style">
            <li>
              <b>ODD:</b>
              <span>J K A</span>
            </li>
            <li>
              <b>DULHA DULHAN:</b>
              <span>Q K</span>
              <span>Payout: 1.97</span>
            </li>
            <li>
              <b>BARATI:</b>
              <span>A J</span>
              <span>Payout: 1.97</span>
            </li>
            <li>
              <b>RED:</b>
              <span>Payout: 1.97</span>
            </li>
            <li>
              <b>BLACK:</b>
              <span>Payout: 1.97</span>
            </li>
            <li>
              <span>J,Q,K,A</span>
              <div>PAYOUT: 3.75</div>
            </li>
            <li>A = DON</li>
            <li>B = AMAR AKBAR ANTHONY</li>
            <li>C = SAHIB BIBI AUR GHULAM</li>
            <li>D = DHARAM VEER</li>
            <li>E = KIS KISKO PYAAR KAROON</li>
            <li>F = GHULAM</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
