import React from "react";
import {
  changeButtonValuesAPI,
  getButtonValuesAPI,
} from "../../service/AuthService";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
export default function ChangeButtonValue() {
  const [buttons, setButtons] = React.useState({});
  const handleUpdate = (key, value) => {
    setButtons((prev) => ({ ...prev, [key]: value }));
  };
  const getButtonValues = async () => {
    const { response } = await getButtonValuesAPI();
    setButtons(response);
  };
  const handleSave = async () => {
    const { response, code } = await changeButtonValuesAPI(buttons);
    if (code === 200) {
      toast.success("Button Values Updated Successfully");
    }
    getButtonValues();
  };
  useEffect(() => {
    getButtonValues();
  }, []);
  const isMobile = window.innerWidth < 768;
  return (
    <div
      style={{ width: isMobile ? "100%" : "82%" }}
      className={`col-md-12 report-main-content  m-t-5`}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Change Button Values</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5 button-value">
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="button-title">
                <span>
                  <b>Price Label</b>
                </span>
              </div>
            </div>
            <div className="col-3">
              <div className="button-title">
                <span>
                  <b>Price Value</b>
                </span>
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName1}
                  onChange={(e) =>
                    handleUpdate("buttonName1", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={buttons?.buttonValue1}
                  onChange={(e) =>
                    handleUpdate("buttonValue1", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName2}
                  onChange={(e) =>
                    handleUpdate("buttonName2", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={buttons?.buttonValue2}
                  onChange={(e) =>
                    handleUpdate("buttonValue2", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName3}
                  onChange={(e) =>
                    handleUpdate("buttonName3", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={buttons?.buttonValue3}
                  onChange={(e) =>
                    handleUpdate("buttonValue3", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName4}
                  onChange={(e) =>
                    handleUpdate("buttonName4", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={buttons?.buttonValue4}
                  onChange={(e) =>
                    handleUpdate("buttonValue4", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName5}
                  onChange={(e) =>
                    handleUpdate("buttonName5", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  value={buttons?.buttonValue5}
                  onChange={(e) =>
                    handleUpdate("buttonValue5", e.target.value)
                  }
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName6}
                  onChange={(e) =>
                    handleUpdate("buttonName6", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={buttons?.buttonValue6}
                  onChange={(e) =>
                    handleUpdate("buttonValue6", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName7}
                  onChange={(e) =>
                    handleUpdate("buttonName7", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={buttons?.buttonValue7}
                  onChange={(e) =>
                    handleUpdate("buttonValue7", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName8}
                  onChange={(e) =>
                    handleUpdate("buttonName8", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={buttons?.buttonValue8}
                  onChange={(e) =>
                    handleUpdate("buttonValue8", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row row5 mb-1">
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Name"
                  type="text"
                  maxLength={7}
                  className="form-control"
                  value={buttons?.buttonName9}
                  onChange={(e) =>
                    handleUpdate("buttonName9", e.target.value)
                  }
                />
              </div>
            </div>
            <div className={isMobile ? "col-6" : "col-3"}>
              <div className="form-group mb-0">
                <input
                  placeholder="Button Value"
                  type="number"
                  min={1}
                  max={99999999}
                  maxLength={9}
                  className="form-control"
                  value={buttons?.buttonValue9}
                  onChange={(e) =>
                    handleUpdate("buttonValue9", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          <div className="row row5 mt-2">
            <div className="col-12">
              <button onClick={handleSave} className="btn btn-primary">
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
