import moment from "moment";
import React, { useEffect } from "react";
import { getProfitLossReportAPI } from "../../service/AuthService";
import { useSelector } from "react-redux";

export default function ProfitLossReport() {
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );
  const userData = useSelector((state) => state.account.userData);
  const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));
  const [data, setData] = React.useState([]);
  const getProfitLoss = async () => {
    const { response, code } = await getProfitLossReportAPI(
      new Date(fromDate),
      new Date(toDate),
      userData.username
    );
    if (code == 200) {
      setData(response);
    }
  };
  useEffect(() => {
    getProfitLoss();
  }, []);
  const handleSubmit = () => {
    getProfitLoss();
  };
  const isMobile = window.innerWidth < 786;
  return (
    <div
      style={{
        width: isMobile ? "100%" : "82%",
      }}
      className={` m-t-5`}
    >
      <div className="card">
        <div className="card-header">
          <h4 className="mb-0">Profit Loss</h4>
        </div>
        <div className="card-body container-fluid container-fluid-5">
          <div className="row row5">
            <div className={isMobile ? "col-12" : "col-2"}>
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  placeholder="Select start date"
                />
              </div>
            </div>
            <div className={isMobile ? "col-12 mt-2" : "col-2"}>
              <div className="form-group mb-0">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  value={toDate}
                  placeholder="Select end date"
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            <div
              onClick={handleSubmit}
              className={isMobile ? "col-12 mt-2" : "col-1"}
            >
              <button className="btn btn-primary btn-block">Submit</button>
            </div>
          </div>

          <div className="row row5 mt-2">
            <div className="col-12">
              <div className="table-responsive">
                <table
                  role="table"
                  aria-busy="false"
                  aria-colcount={3}
                  className="table b-table table-striped table-bordered"
                  id="__BVID__109"
                >
                  <thead role="rowgroup" className="">
                    <tr role="row" className="">
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={1}
                        className="text-center"
                      >
                        Event Type
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={2}
                        className="text-center"
                      >
                        Event Name
                      </th>
                      <th
                        role="columnheader"
                        scope="col"
                        aria-colindex={3}
                        className="text-right"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    {data?.map((item) => (
                      <tr role="row" className="">
                        <td role="cell" className="text-center">
                          {item?.eventType}
                        </td>
                        <td role="cell" className="text-center">
                          {item?.eventName}
                        </td>
                        <td
                          role="cell"
                          className={`text-right ${
                            item?.amount < 0 ? "text-danger" : "text-success"
                          }`}
                        >
                          {item?.amount}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row row5 mt-2">
            <div className="col-12" />
          </div>
        </div>
      </div>
    </div>
  );
}
