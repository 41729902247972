import { createSlice } from "@reduxjs/toolkit";
import { INITIAL_STATE } from "../initialState";
export const gameSlice = createSlice({
         name: "gameData",
         initialState: INITIAL_STATE,
         reducers: {
           setCricketOdds: (state, action) => {
             let payload = state.cricketOdds;
             Object.keys(action.payload).map((key) => {
               payload[key] = action.payload[key];
             });
             state.cricketOdds = payload;
           },

           setTennisOdds: (state, action) => {
             let payload = state.tennisOdds;
             Object.keys(action.payload).map((key) => {
               payload[key] = action.payload[key];
             });
             state.tennisOdds = payload;
           },
           setSoccerOdds: (state, action) => {
             let payload = state.soccerOdds;
             Object.keys(action.payload).map((key) => {
               payload[key] = action.payload[key];
             });
             state.soccerOdds = payload;
           },
           setTennisGames: (state, action) => {
             state.tennisGames = action.payload;
           },
           setCricketGames: (state, action) => {
             state.cricketGames = action.payload;
           },
           setSoccerGames: (state, action) => {
             state.soccerGames = action.payload;
           },
           setSelectedEventForBet: (state, action) => {
             if (action.payload === null) {
               state.selectedEventForBet = null;
             } else {
               state.selectedEventForBet = {
                 ...state.selectedEventForBet,
                 ...action.payload,
               };
             }
           },
           setCasinoOdds: (state, action) => {
             state.casinoOdds = action.payload;
           },
           setMatchSettings: (state, action) => {
             let { game, settings, eventId } = action.payload;
             if (game === "cricket") {
               state.cricketSettings[eventId] = settings;
             }
             if (game === "tennis") {
               state.tennisSettings[eventId] = settings;
             }
             if (game === "soccer") {
               state.soccerSettings[eventId] = settings;
             }
           },
           setTeenOdds: (state, action) => {
             state.teenOdds = action.payload;
           },
           setTeenPatti20Odds: (state, action) => {
             state.teenPatti20Odds = action.payload;
           },
           setLucky7Odds: (state, action) => {
             state.lucky7Odds = action.payload;
           },
           setLucky7euOdds: (state, action) => {
             state.lucky7euOdds = action.payload;
           },
           setCard32Odds: (state, action) => {
             state.card32Odds = action.payload;
           },
           setAndarBahar20Odds: (state, action) => {
             state.andarBahar20Odds = action.payload;
           },
           setPoker20Odds: (state, action) => {
             state.poker20Odds = action.payload;
           },
           setDragonTiger20Odds: (state, action) => {
             state.dragonTiger20Odds = action.payload;
           },
           setDragonTiger202Odds: (state, action) => {
             state.dragonTiger202Odds = action.payload;
           },
           setCasinoQueenOdds: (state, action) => {
             state.casinoQueenOdds = action.payload;
           },
           setDragonTigerLionOdds: (state, action) => {
             state.dragonTigerLionOdds = action.payload;
           },
           setTeen8Odds: (state, action) => {
             state.teen8Odds = action.payload;
           },
           setTeen9Odds: (state, action) => {
             state.teen9Odds = action.payload;
           },
           setCasinoMeterOdds: (state, action) => {
             state.casinoMeterOdds = action.payload;
           },
           setPoker9Odds: (state, action) => {
             state.poker9Odds = action.payload;
           },

           setCard32euOdds: (state, action) => {
             state.card32euOdds = action.payload;
           },
           setRace20Odds: (state, action) => {
             state.race20Odds = action.payload;
           },
           setWorli2Odds: (state, action) => {
             state.worli2Odds = action.payload;
           },
           setBollywoodTableOdds: (state, action) => {
             state.bollywoodTableOdds = action.payload;
           },
           setCasinoWarOdds: (state, action) => {
             state.casinoWarOdds = action.payload;
           },
           setAbjOdds: (state, action) => {
             state.abjOdds = action.payload;
           },
           setBaccarat20Odds: (state, action) => {
             state.baccarat2Odds = action.payload;
           },
           setBaccaratOdds: (state, action) => {
             state.baccaratOdds = action.payload;
           },
           setCmatch20Odds: (state, action) => {
             state.cmatch20Odds = action.payload;
           },
           setWorliOdds: (state, action) => {
             state.worliOdds = action.payload;
           },
           setCricketv3Odds: (state, action) => {
             state.cricketv3Odds = action.payload;
           },
           setAaaOdds: (state, action) => {
             state.aaaOdds = action.payload;
           },
           setDragonTigerOneDayOdds: (state, action) => {
             state.dragonTigerOneDayOdds = action.payload;
           },
           setSuperOverOdds: (state, action) => {
             state.superOverOdds = action.payload;
           },

           logOut: (state, action) => {
             state.selectedEventForBet = null;
           },
         },
       });

//export const { increment, decrement, incrementByAmount, spin } = game.actions;
export default gameSlice.reducer;
