import React, { useEffect } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import {
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import CasinoPage from "./components/CasinoPage";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import { getCasinoExposureAPI, getCasinoOddsAPI } from "../../service/casino";
export default function TeenPatti() {
  const GAME_ID = "TEEN_20";
  const VIDEO_ID = CASINO_VIDEO.teenPattiT20;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const [casinoPlData, setCasinoPlData] = React.useState({});
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.teenPattiT20}
      GAME_ID={GAME_ID}
      shortName={"teenpatti20"}
      gameName={"TEEN PATTI 2020"}
      rule={"tp20"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-10 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-box ng-tns-c1798067373-10"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box w-100 ng-tns-c1798067373-10"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-10"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-10"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="min_max_odds justify-content-start d-lg-none d-flex ng-tns-c1798067373-10"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-0 ng-tns-c1798067373-10"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-10"
                    >
                      Min:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-10"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-10 ng-star-inserted"
                      >
                        {" "}
                        100{" "}
                      </span>
                      {/**/}
                      {/**/}
                    </span>
                  </span>
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="min_max_span ms-1 ng-tns-c1798067373-10"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-10"
                    >
                      Max:
                    </b>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-10"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-10 ng-star-inserted"
                      >
                        {" "}
                        150000{" "}
                      </span>
                      {/**/}
                      {/**/}
                    </span>
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino_odds_row ng-tns-c1798067373-10"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-10"
                >
                  Back
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds-box back ng-tns-c1798067373-10"
                />
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-10"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-10"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-10"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-10"
                  >
                    {" "}
                    Player A{" "}
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-10 casino_odds_row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    onClick={() =>
                      setBet("Player A", casinoData, dispatch, "back")
                    }
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-10"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-10"
                    >
                      {getOddForEntity("Player A", casinoData)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-10"
                    >
                      {" "}
                      {getCasinoPlExposureByRunner(
                        casinoPlData,
                        "Player A"
                      )}{" "}
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      setBet("Pair Plus A", casinoData, dispatch, "back")
                    }
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-10"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-10"
                    >
                      {" "}
                      Pair Plus A
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-10"
                    >
                      {getCasinoPlExposureByRunner(casinoPlData, "Pair Plus A")}
                    </p>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-10"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-10"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-10"
                  >
                    {" "}
                    Player B{" "}
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-10 casino_odds_row ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                >
                  <div
                    onClick={() =>
                      setBet("Player B", casinoData, dispatch, "back")
                    }
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-10"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-10"
                    >
                      {getOddForEntity("Player B", casinoData)}
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-10"
                    >
                      {getCasinoPlExposureByRunner(casinoPlData, "Player B")}
                    </p>
                  </div>
                  <div
                    onClick={() =>
                      setBet("Pair Plus B", casinoData, dispatch, "back")
                    }
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds-box back ng-tns-c1798067373-10"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-10"
                    >
                      {" "}
                      Pair Plus B
                    </span>
                    <p
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-10"
                    >
                      {getCasinoPlExposureByRunner( casinoPlData,"Pair Plus B")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
