import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import CasinoPage from "./components/CasinoPage";
export default function AndarBahar2() {
  const GAME_ID = "ABJ";
  const VIDEO_ID = CASINO_VIDEO.anderBahr2;
  const dispatch = useDispatch();
  const [casinoPlData, setCasinoPlData] = useState([]);
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );

  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  return (
    <CasinoPage
      shortName={"ab2"}
      casino={casinoData?.data}
      VIDEO_ID={VIDEO_ID}
      GAME_ID={GAME_ID}
      gameName={"Andar Bahar 2"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-detail ng-tns-c1798067373-3 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table ng-tns-c1798067373-3"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-full-box ng-tns-c1798067373-3"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="playera-bets ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="playera-title ng-tns-c1798067373-3"
              >
                A
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="player-sa ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`player-sa-box ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("SA", casinoData, dispatch)}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-3"
                    >
                      {" "}
                      SA{" "}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-volume ng-tns-c1798067373-3"
                    >
                      {" "}
                      {getOddForEntity("SA", casinoData, true)}
                    </div>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "SA")}
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="player-bet ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`player-bet-box ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() =>
                    setBet("1st Bet Player A", casinoData, dispatch)
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-3"
                    >
                      {" "}
                      1st Bet A{" "}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-volume ng-tns-c1798067373-3"
                    >
                      {" "}
                      {getOddForEntity("1st Bet Player A", casinoData, true)}
                    </div>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "1st Bet Player A"
                  )}
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="player-bet ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`player-bet-box ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() =>
                    setBet("2nd Bet Player A", casinoData, dispatch)
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-3"
                    >
                      {" "}
                      2nd Bet A{" "}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-volume ng-tns-c1798067373-3"
                    >
                      {" "}
                      {getOddForEntity("2nd Bet Player A", casinoData, true)}
                    </div>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "2nd Bet Player A"
                  )}
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="playera-title ng-tns-c1798067373-3"
              >
                A
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="playera-bets ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="playera-title ng-tns-c1798067373-3"
              >
                B
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="player-sa ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`player-sa-box ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("SB", casinoData, dispatch)}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-3"
                    >
                      {" "}
                      SB{" "}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-volume ng-tns-c1798067373-3"
                    >
                      {" "}
                      {getOddForEntity("SB", casinoData, true)}
                    </div>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "SB")}
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="player-bet ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`player-bet-box ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() =>
                    setBet("1st Bet Player B", casinoData, dispatch)
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-3"
                    >
                      {" "}
                      1st Bet B{" "}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-volume ng-tns-c1798067373-3"
                    >
                      {" "}
                      {getOddForEntity("1st Bet Player B", casinoData, true)}
                    </div>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "1st Bet Player B"
                  )}
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="player-bet ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`player-bet-box ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() =>
                    setBet("2nd Bet Player B", casinoData, dispatch)
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-odds ng-tns-c1798067373-3"
                    >
                      {" "}
                      2nd Bet B{" "}
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-volume ng-tns-c1798067373-3"
                    >
                      {" "}
                      {getOddForEntity("2nd Bet Player B", casinoData, true)}
                    </div>
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(
                    casinoPlData,
                    "2nd Bet Player B"
                  )}
                </div>
              </div>
              {/**/}
              {/**/}
              {/**/}
              <div
                _ngcontent-ng-c1798067373=""
                className="playera-title ng-tns-c1798067373-3"
              >
                B
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-box mt-3 ng-tns-c1798067373-3"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-left-box ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="ab2-box ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-3 ng-star-inserted"
                >
                  {" "}
                  ODD{" "}
                </div>
                {/**/}
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`casino-odds-box back ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("joker ODD", casinoData, dispatch)}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-3"
                  >
                    {getOddForEntity("joker ODD", casinoData, true)}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "joker ODD")}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ab2-box ng-tns-c1798067373-3 ng-star-inserted"
              >
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-3 ng-star-inserted"
                >
                  {" "}
                  EVEN{" "}
                </div>
                {/**/}
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`casino-odds-box back ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("joker EVEN", casinoData, dispatch)}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-3"
                  >
                    {getOddForEntity("joker EVEN", casinoData, true)}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "joker EVEN")}
                </div>
              </div>
              {/**/}
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-right-box ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="ab2-box ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-3"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 ng-star-inserted"
                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                  />
                  {/**/}
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`casino-odds-box back ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Joker Spade", casinoData, dispatch)}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-3"
                  >
                    {getOddForEntity("Joker Spade", casinoData, true)}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Joker Spade")}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ab2-box ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-3"
                >
                  {/**/}
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 ng-star-inserted"
                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                  />
                  {/**/}
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`casino-odds-box back ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Joker Club", casinoData, dispatch)}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-3"
                  >
                    {getOddForEntity("Joker Club", casinoData, true)}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Joker Club")}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ab2-box ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-3"
                >
                  {/**/}
                  {/**/}
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 ng-star-inserted"
                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                  />
                  {/**/}
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`casino-odds-box back ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() => setBet("Joker Heart", casinoData, dispatch)}
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-3"
                  >
                    {getOddForEntity("Joker Heart", casinoData, true)}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Joker Heart")}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="ab2-box ng-tns-c1798067373-3 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-odds text-center ng-tns-c1798067373-3"
                >
                  {/**/}
                  {/**/}
                  {/**/}
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3 ng-star-inserted"
                    src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                  />
                  {/**/}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`casino-odds-box back ng-tns-c1798067373-3   ${
                    casinoData?.data?.response?.autoTime < 5
                      ? "suspended-box"
                      : ""
                  }`}
                  onClick={() =>
                    setBet("Joker Diamond", casinoData, dispatch)
                  }
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-3"
                  >
                    {getOddForEntity("Joker Diamond", casinoData, true)}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-book text-center ng-tns-c1798067373-3"
                >
                  {" "}
                  {getCasinoPlExposureByRunner(casinoPlData, "Joker Diamond")}
                </div>
              </div>
              {/**/}
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-full-box ab2cards mt-3 ng-tns-c1798067373-3"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="text-center w-100 ng-tns-c1798067373-3"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-bl-box casino-cards-odds-title ng-tns-c1798067373-3"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-bl-box-item ng-tns-c1798067373-3"
                >
                  <b
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-3"
                  >
                    {" "}
                    {getOddForEntity("Joker A", casinoData, true)}{" "}
                  </b>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker A", casinoData, dispatch)}
              >
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker A")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 2", casinoData, dispatch)}
              >
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 2")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 3", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/3.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 3")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 4", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/4.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 4")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 5", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/5.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 5")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 6", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/6.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 6")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 7", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 7")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 8", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 8")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 9", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/9.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 9")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker 10", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/10.webp"
                />
                {/**/}
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker 10")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker J", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                />
                {/**/}
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker J")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker Q", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                />
                {/**/}
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker Q")}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="card-odd-box ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className={`ng-tns-c1798067373-3 ${
                  casinoData?.data?.response?.autoTime < 5
                    ? "suspended-box"
                    : ""
                }`}
                onClick={() => setBet("Joker K", casinoData, dispatch)}
              >
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                {/**/}
                <img
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-3 ng-star-inserted"
                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                />
                {/**/}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-book ng-tns-c1798067373-3"
              >
                {" "}
                {getCasinoPlExposureByRunner(casinoPlData, "Joker K")}
              </div>
            </div>
            {/**/}
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
